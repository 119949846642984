import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesNavModule } from './extensions/0763c9e2a4af427b3eea647df0a109a9e2442e1568b387a6b4b1985efc131863/invoices-nav.module';

import SharedProviders_0_0 from './extensions/0763c9e2a4af427b3eea647df0a109a9e2442e1568b387a6b4b1985efc131863/providers';


@NgModule({
    imports: [CommonModule, InvoicesNavModule],
    providers: [...SharedProviders_0_0],
})
export class SharedExtensionsModule {}
